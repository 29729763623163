import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  useTheme,
  Button,
  Typography,
  MenuItem,
  Select,
  Drawer,
  TextField,
  Grid,
  Checkbox,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import { Upload, CloseFullscreen } from "@mui/icons-material";
import Header from "../../component/Header";
import {
  updateItemsAction,
  fetchItemAction,
  resetState,
} from "../../state/items/itemsSlice";
import Dropzone from "react-dropzone";
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Close } from "@mui/icons-material";
import InputAdornment from "@mui/material/InputAdornment";
import Loader from "../../component/loader";
import { fetchCategoriesAction } from "../../state/categories/categorySlice";

const EditItem = ({ open, handleClose, id }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [fileName, setFileName] = useState("No file chosen");
  const isSuccess = useSelector((state) => state.items.isSuccess);
  const appErr = useSelector((state) => state.items.appErr);
  // const { id } = useParams();

  const categories = useSelector((state) => state.categories.catDetails);
  const extras = useSelector((state) => state.extras.Details);
  const extrasData = useSelector((state) => state.ingredientsExtras.Details);
  useEffect(() => {
    if (id) {
      dispatch(fetchItemAction(id));
    }
  }, [dispatch, id]);

  const item = useSelector((state) => state.items.item);
  const Updated = useSelector((state) => state?.items.Updated);
  const loading = useSelector((state) => state?.items.loading);

  useEffect(() => {
    if (isSuccess && Updated) {
      toast.success("Details Edited Successfully!");
    }
  }, [isSuccess, Updated]);

  useEffect(() => {
    if (appErr) {
      toast.error(appErr);
    }
  }, [appErr]);
  useEffect(() => {
    // Dispatch an action to fetch categories
    dispatch(fetchCategoriesAction());
    if (id) {
      dispatch(fetchItemAction(id));
    }
  }, [dispatch, isSuccess, id]);
  // Define validation schema
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    catId: Yup.string().required("Category is required"),
    categoryId: Yup.number().required("CategoryId is required"),
    initialPrice: Yup.number().required("Initial price is required"),
    offerPrice: Yup.number(),
    main_image: Yup.mixed().nullable(),
    ingredients: Yup.array().nullable(),
    extras: Yup.array().of(
      Yup.object().shape({
        name: Yup.string(),
        price: Yup.number(),
      })
    ),
    ingredientsExtras: Yup.array().of(
      Yup.object().shape({
        name: Yup.string(),
        allowedQuantity: Yup.number(),
        extras: Yup.array().of(
          Yup.object().shape({
            name: Yup.string(),
          })
        ),
      })
    ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: item?.name || "",
      catId: item?.catId || "",
      categoryId: item?.categoryId || "",
      initialPrice: item?.initialPrice || "",
      offerPrice: item?.offerPrice || "",
      main_image: item?.main_image || null,
      ingredients: item?.ingredients || [""],
      extras: item?.extras || [{ name: "", price: "" }],

      ingredientsExtras: item?.ingredientsExtras || [
        { name: "", allowedQuantity: "", extras: [{ name: "" }] },
      ],
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const selectedExtras = values.extras.filter(
        (extra) => extra.name !== "" || extra.price !== ""
      );

      if (selectedExtras.length === 0) {
        // If no extras other than { name: '', price: '' } are selected, remove extras from form values
        delete values.extras;
      } else {
        // Otherwise, update form values with selected extras
        values.extras = selectedExtras;
      }

      // Check if ingredientsExtras contain any non-empty elements
      const nonEmptyIngredientsExtras = values.ingredientsExtras.filter(
        (ingredientExtra) =>
          ingredientExtra.name !== "" ||
          ingredientExtra.allowedQuantity !== "" ||
          (ingredientExtra.extras && ingredientExtra.extras.length > 0)
      );

      // Only include ingredientsExtras if it's not empty
      if (nonEmptyIngredientsExtras.length === 0) {
        delete values.ingredientsExtras;
      }
      const data = {
        name: values?.name,
        catId: values?.catId,
        categoryId: values?.categoryId,
        initialPrice: values?.initialPrice,
        offerPrice: values?.offerPrice,
        main_image: values?.main_image,
        ingredients: values?.ingredients,
        extras: values?.extras,
        ingredientsExtras: values?.ingredientsExtras,
      };
      dispatch(updateItemsAction({ data, id }));
      console.log(values);
      setFileName("No file chosen");
      setSubmitting(false);
      handleClose();
      setTimeout(() => {
        dispatch(resetState());
      }, 3000);
    },
  });
  const addIngredientField = () => {
    formik.setFieldValue("ingredients", [...formik.values.ingredients, ""]);
  };
  const removeIngredientField = (indexToRemove) => {
    // Check if there is more than one ingredient
    if (formik.values.ingredients.length > 1) {
      const updatedIngredients = formik.values.ingredients.filter(
        (_, index) => index !== indexToRemove
      );
      formik.setFieldValue("ingredients", updatedIngredients);
    }
  };
  const drawerStyle = {
    backgroundColor: theme.palette.background.alt,
  };
  const addIngredientExtrasField = () => {
    formik.setFieldValue("ingredientsExtras", [
      ...formik.values.ingredientsExtras,
      { name: "", allowedQuantity: "", extras: [] },
    ]);
  };
  const removeIngredientExtrasField = (indexToRemove) => {
    // Check if there is more than one ingredientsExtras
    if (formik.values.ingredientsExtras.length > 1) {
      const updatedIngredientsExtras = formik.values.ingredientsExtras.filter(
        (_, index) => index !== indexToRemove
      );
      formik.setFieldValue("ingredientsExtras", updatedIngredientsExtras);
    }
  };
  return (
    <React.Fragment>
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        PaperProps={{ sx: { width: "75%" } }}
      >
        <Box sx={drawerStyle}>
          <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
            <Box
              p="1rem"
              sx={{
                top: 0,
                height: "50px",
                background: "white",
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                gap: 2,
                zIndex: 100,
                width: "100%",
                position: "fixed",
              }}
            >
              <Header subtitle="Edit Item Details" />
              <CloseFullscreen
                onClick={handleClose}
                color="secondary"
                sx={{ cursor: "pointer", zIndex: 100 }}
              />
            </Box>

            <Box m="5rem 1rem 1.5rem 1rem">
              <Grid container spacing={2}>
                {/* Left section containing Name and Category */}
                <Grid item xs={12} md={6}>
                  <Grid item xs={12} md={12}>
                    <Box
                      p={2}
                      borderRadius={4}
                      bgcolor={theme.palette.background.default}
                      boxShadow={theme.shadows[3]}
                    >
                      <Typography variant="h6" fontWeight="bold" gutterBottom>
                        Main Item Details:
                      </Typography>
                      <hr
                        style={{
                          border: "1px solid #f0f0f0",
                          margin: "10px 0",
                        }}
                      />
                      <Typography variant="h6" gutterBottom>
                        Name: <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <TextField
                        id="name"
                        name="name"
                        variant="outlined"
                        fullWidth
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.name && Boolean(formik.errors.name)
                        }
                        helperText={formik.touched.name && formik.errors.name}
                      />
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography variant="h6" gutterBottom mt={2}>
                          Category: <span style={{ color: "red" }}>*</span>
                        </Typography>
                      </Box>
                      <Select
                        id="catId"
                        name="catId"
                        variant="outlined"
                        value={formik.values.catId}
                        onChange={(event) => {
                          const selectedCategoryId = event.target.value;
                          // Set catId to _id and categoryId to id
                          formik.setFieldValue("catId", selectedCategoryId);
                          formik.setFieldValue(
                            "categoryId",
                            categories.find(
                              (category) => category._id === selectedCategoryId
                            )?.id || ""
                          );
                        }}
                        error={
                          formik.touched.catId && Boolean(formik.errors.catId)
                        }
                        fullWidth
                      >
                        {categories &&
                          categories.map((category) => (
                            <MenuItem key={category._id} value={category._id}>
                              {category.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </Box>
                  </Grid>

                  {/* Right section containing Initial Price, Offer Price */}
                  <Grid item xs={12} md={12}>
                    <Box
                      p={2}
                      mt={2}
                      borderRadius={4}
                      bgcolor={theme.palette.background.default}
                      boxShadow={theme.shadows[3]}
                    >
                      <Typography variant="h6" fontWeight="bold" gutterBottom>
                        Item Prices Details:
                      </Typography>
                      <hr
                        style={{
                          border: "1px solid #f0f0f0",
                          margin: "10px 0",
                        }}
                      />
                      <Typography variant="h6" gutterBottom>
                        Initial Price: <span style={{ color: "red" }}>*</span>
                      </Typography>
                      <TextField
                        id="initialPrice"
                        name="initialPrice"
                        variant="outlined"
                        fullWidth
                        value={formik.values.initialPrice}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.initialPrice &&
                          Boolean(formik.errors.initialPrice)
                        }
                        helperText={
                          formik.touched.initialPrice &&
                          formik.errors.initialPrice
                        }
                      />
                      <Typography variant="h6" gutterBottom mt={2}>
                        Offer Price:
                      </Typography>
                      <TextField
                        id="offerPrice"
                        name="offerPrice"
                        variant="outlined"
                        fullWidth
                        value={formik.values.offerPrice}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.offerPrice &&
                          Boolean(formik.errors.offerPrice)
                        }
                        helperText={
                          formik.touched.offerPrice && formik.errors.offerPrice
                        }
                      />
                    </Box>
                  </Grid>

                  {/* Ingredients Section */}
                  <Grid item xs={12} md={12}>
                    <Box
                      p={2}
                      mt={2}
                      borderRadius={4}
                      bgcolor={theme.palette.background.default}
                      boxShadow={theme.shadows[3]}
                    >
                      <Typography variant="h6" fontWeight="bold" gutterBottom>
                        Item Ingredients:
                      </Typography>
                      <hr
                        style={{
                          border: "1px solid #f0f0f0",
                          margin: "10px 0",
                        }}
                      />
                      <Typography variant="h6" gutterBottom>
                        Ingredients:
                      </Typography>
                      {/* Dynamic Ingredients */}
                      {formik.values.ingredients.map((ingredient, Index) => (
                        <Box key={Index} mt={3}>
                          <TextField
                            id={`ingredients[${Index}]`}
                            name={`ingredients[${Index}]`}
                            label={`Ingredient ${Index + 1}`}
                            variant="outlined"
                            fullWidth
                            value={formik.values.ingredients[Index]}
                            onChange={formik.handleChange}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() => removeIngredientField(Index)}
                                    color="secondary"
                                    size="medium"
                                    disabled={
                                      formik.values.ingredients.length === 1
                                    }
                                  >
                                    <Close fontSize="medium" />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Box>
                      ))}
                      <Button onClick={addIngredientField} color="secondary">
                        Add Ingredient
                      </Button>
                    </Box>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  {/* Image Section */}
                  <Grid item xs={12} md={12}>
                    <Box
                      p={2}
                      borderRadius={4}
                      bgcolor={theme.palette.background.default}
                      boxShadow={theme.shadows[3]}
                    >
                      <Typography variant="h6" fontWeight="bold" gutterBottom>
                        Main Image Upload:
                      </Typography>
                      <hr
                        style={{
                          border: "1px solid #f0f0f0",
                          margin: "10px 0",
                        }}
                      />
                      <Typography variant="h6" gutterBottom>
                        Image: <span style={{ color: "red" }}>*</span>
                      </Typography>
                      {/* Dropzone for Main Image */}
                      <Dropzone
                        onDrop={(acceptedFiles) => {
                          formik.setFieldValue("main_image", acceptedFiles[0]);
                          setFileName(acceptedFiles[0].name);
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()}>
                            <input {...getInputProps()} accept="image/*" />
                            <Button
                              variant="contained"
                              startIcon={<Upload />}
                              color="secondary"
                              style={{ marginTop: "25px" }}
                            >
                              Choose Main Image
                            </Button>
                          </div>
                        )}
                      </Dropzone>

                      <div style={{ color: "#d32f2f" }}>
                        {formik.touched.main_image &&
                          Boolean(formik.errors.main_image)}
                        {formik.touched.main_image && formik.errors.main_image}
                      </div>
                      <span>{fileName}</span>
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Box
                      p={2}
                      mt={2}
                      borderRadius={4}
                      bgcolor={theme.palette.background.default}
                      boxShadow={theme.shadows[3]}
                    >
                      <Typography variant="h6" fontWeight="bold" gutterBottom>
                        Ingredients Extras:
                      </Typography>
                      <hr
                        style={{
                          border: "1px solid #f0f0f0",
                          margin: "10px 0",
                        }}
                      />

                      {formik.values.ingredientsExtras &&
                        formik.values.ingredientsExtras.map(
                          (ingredient, index) => (
                            <div key={index}>
                              <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Typography
                                  variant="h6"
                                  gutterBottom
                                  style={{ marginRight: "8px" }}
                                >
                                  Ingredient {index + 1}
                                </Typography>
                                <IconButton
                                  onClick={() =>
                                    removeIngredientExtrasField(index)
                                  }
                                  color="secondary"
                                  size="medium"
                                  disabled={
                                    formik.values.ingredientsExtras.length === 1
                                  }
                                >
                                  <Close fontSize="medium" />
                                </IconButton>
                              </Box>

                              <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                  <Typography variant="h6" gutterBottom mt={2}>
                                    Extras Name:
                                  </Typography>
                                  <Select
                                    id={`ingredientsExtras[${index}].name`}
                                    name={`ingredientsExtras[${index}].name`}
                                    variant="outlined"
                                    fullWidth
                                    value={
                                      formik.values.ingredientsExtras[index]
                                        .name
                                    }
                                    onChange={(event) => {
                                      const selectedName = event.target.value;
                                      if (selectedName === "") {
                                        // If "None" is selected, clear the extras for this ingredient
                                        formik.setFieldValue(
                                          `ingredientsExtras[${index}].extras`,
                                          []
                                        );
                                        formik.setFieldValue(
                                          `ingredientsExtras[${index}].allowedQuantity`,
                                          ""
                                        );
                                      }
                                      // Set the selected name in formik values
                                      formik.handleChange(event);
                                    }}
                                    error={
                                      formik.touched.ingredientsExtras &&
                                      formik.touched.ingredientsExtras[index]
                                        ?.name &&
                                      Boolean(
                                        formik.errors.ingredientsExtras?.[index]
                                          ?.name
                                      )
                                    }
                                    helpertext={
                                      formik.touched.ingredientsExtras &&
                                      formik.touched.ingredientsExtras[index]
                                        ?.name &&
                                      formik.errors.ingredientsExtras?.[index]
                                        ?.name
                                    }
                                  >
                                    <MenuItem value="">
                                      <em>None</em>
                                    </MenuItem>
                                    {extrasData &&
                                      Array.from(
                                        new Set(
                                          extrasData.map((extra) => extra.type)
                                        )
                                      ).map((type, index) => (
                                        <MenuItem
                                          key={index}
                                          value={type}
                                          disabled={formik.values.ingredientsExtras.some(
                                            (extras) => extras.name === type
                                          )}
                                        >
                                          {type}
                                        </MenuItem>
                                      ))}
                                  </Select>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                  <Typography variant="h6" gutterBottom mt={2}>
                                    Quantity:
                                  </Typography>
                                  <TextField
                                    id={`ingredientsExtras[${index}].allowedQuantity`}
                                    name={`ingredientsExtras[${index}].allowedQuantity`}
                                    label={`Allowed Quantity`}
                                    variant="outlined"
                                    fullWidth
                                    value={
                                      formik.values.ingredientsExtras[index]
                                        .allowedQuantity
                                    }
                                    onChange={formik.handleChange}
                                    error={
                                      formik.touched.ingredientsExtras &&
                                      formik.touched.ingredientsExtras[index]
                                        ?.allowedQuantity &&
                                      Boolean(
                                        formik.errors.ingredientsExtras?.[index]
                                          ?.allowedQuantity
                                      )
                                    }
                                    helpertext={
                                      formik.touched.ingredientsExtras &&
                                      formik.touched.ingredientsExtras[index]
                                        ?.allowedQuantity &&
                                      formik.errors.ingredientsExtras?.[index]
                                        ?.allowedQuantity
                                    }
                                  />
                                </Grid>
                              </Grid>
                              <Typography variant="h6" gutterBottom mt={2}>
                                Extras :
                              </Typography>
                              {formik.values.ingredientsExtras[index].extras &&
                                extrasData &&
                                extrasData
                                  .filter(
                                    (extra) => extra.type === ingredient.name
                                  )
                                  .map((extra, extraIndex) => {
                                    const isChecked =
                                      formik.values.ingredientsExtras[
                                        index
                                      ].extras.some(
                                        (e) => e.name === extra.name
                                      );
                                    return (
                                      <FormControlLabel
                                        key={extraIndex}
                                        control={
                                          <Checkbox
                                            id={`ingredientsExtras[${index}].extras[${extraIndex}]`}
                                            name={`ingredientsExtras[${index}].extras[${extraIndex}]`}
                                            checked={isChecked}
                                            onChange={(event) => {
                                              const isChecked =
                                                event.target.checked;
                                              if (isChecked) {
                                                formik.setFieldValue(
                                                  `ingredientsExtras[${index}].extras`,
                                                  [
                                                    ...formik.values
                                                      .ingredientsExtras[index]
                                                      .extras,
                                                    { name: extra.name },
                                                  ]
                                                );
                                              } else {
                                                formik.setFieldValue(
                                                  `ingredientsExtras[${index}].extras`,
                                                  formik.values.ingredientsExtras[
                                                    index
                                                  ].extras.filter(
                                                    (item) =>
                                                      item.name !== extra.name
                                                  )
                                                );
                                              }
                                            }}
                                          />
                                        }
                                        label={extra.name}
                                      />
                                    );
                                  })}
                            </div>
                          )
                        )}
                      <Button
                        onClick={addIngredientExtrasField}
                        color="secondary"
                        disabled={
                          Array.from(
                            new Set(
                              extrasData &&
                                extrasData.map((extra) => extra.type)
                            )
                          ).length === formik.values.ingredientsExtras &&
                          formik.values.ingredientsExtras.length
                        }
                      >
                        Add Ingredient Extras
                      </Button>
                    </Box>
                  </Grid>

                  {/* Extras Section */}
                  <Grid item xs={12} md={12}>
                    <Box
                      p={2}
                      mt={2}
                      borderRadius={4}
                      bgcolor={theme.palette.background.default}
                      boxShadow={theme.shadows[3]}
                    >
                      <Typography variant="h6" fontWeight="bold" gutterBottom>
                        Item Extras:
                      </Typography>
                      <hr
                        style={{
                          border: "1px solid #f0f0f0",
                          margin: "10px 0",
                        }}
                      />
                      <Typography variant="h6" gutterBottom>
                        Extras:
                      </Typography>
                      <Grid container spacing={2}>
                        {extras &&
                          extras.map((extra) => (
                            <Grid item xs={6} key={extra.id}>
                              <Box display="flex" alignItems="center">
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      id={`extra-${extra.id}`}
                                      variant="solid"
                                      checked={
                                        formik.values.extras &&
                                        formik.values.extras.some(
                                          (selectedExtra) =>
                                            selectedExtra.name === extra.name
                                        )
                                      }
                                      onChange={(event) => {
                                        const isChecked = event.target.checked;
                                        if (isChecked) {
                                          formik.setFieldValue("extras", [
                                            ...formik.values.extras,
                                            extra,
                                          ]);
                                        } else {
                                          const updatedExtras =
                                            formik.values.extras.filter(
                                              (item) => item.name !== extra.name
                                            );
                                          formik.setFieldValue(
                                            "extras",
                                            updatedExtras
                                          );
                                        }
                                      }}
                                    />
                                  }
                                  label={extra.name}
                                />
                              </Box>
                            </Grid>
                          ))}
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box
              p={2}
              textAlign="center"
              sx={{
                bottom: 0,
                height: "70px",
                zIndex: 999,
                background: "white",
                width: "100%",
              }}
            >
              <Button
                onClick={handleClose}
                variant="contained"
                color="secondary"
                style={{ marginRight: "10px" }}
              >
                Cancel
              </Button>

              <Button
                type="submit"
                variant="contained"
                color="secondary"
                disabled={loading}
              >
                {!loading ? "Submit" : <Loader />}
              </Button>
            </Box>
          </form>
        </Box>
      </Drawer>
    </React.Fragment>
  );
};

export default EditItem;
